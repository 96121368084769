const root = document.querySelector('div.wrapper')

// Section Actions
const sections = document.querySelectorAll('section.observe')
const sectionObserver = new IntersectionObserver(
    entries => {
        entries.forEach(entry => {
            entry.target.classList.toggle('is-visible', entry.isIntersecting)
            if (entry.isIntersecting) sectionObserver.unobserve(entry.target)
        })
    },
    {
        root,
        rootMargin: '0px 0px -20px 0px',
    }
)
sections.forEach(section => {
    sectionObserver.observe(section)
})

// const merchInfo = document.getElementById('merch-info')
// const showPurchase = () => {
//     merchInfo?.parentElement?.classList.add('purchase')
// }
// document.addEventListener('click', _ => {
//     showPurchase()
//     document.removeEventListener('click', showPurchase)
// })
